import ".../utils/jquery.atwho"
import ".../utils/jquery.caret"
import ".../utils/jquery.focus"

$(document).on("turbolinks:load", function() {
  //Set defult tabs
  !!window.location.hash && $('.tabs').tabslet({
    animation: true,
    active: {at:1,like:3,comment:2}[window.location.hash.replace("#","")]||1
  });

  // Footer resize
  if($("body > .container").height() + 60 >= $(window).height()){
    $(".footer").css("position", "relative");
  }

  //Check New Messages
  $('#wb-notice').length > 0 && setInterval(CheckNewMessages, 30000);

  //For weibo option
  $('.microposts').on('click','.wb-option-icon', function(e){
    e.stopPropagation();
    if($(this).next().is(':visible'))
      $(this).next().slideUp('fast');
    else {
      $('.wb-option-menu').hide('fast');
      $(this).next().slideDown('fast');
    }
  });

  //For weibo notice option
  $('#messages').on('click','.notice-option-icon', function(e){
    e.stopPropagation();
    if($(this).next().is(':visible'))
      $(this).next().slideUp('fast');
    else {
      $('.notice-option-menu').hide('fast');
      $(this).next().slideDown('fast');
    }
  });

  //For weibo comment
  $('.wb-comment-input, .wb-comment-icon, .wb-comment-reply').on('click', function(e) {
  //$('.microposts').delegate('.wb-comment-icon, .wb-comment-reply','click', function(e) {
    e.stopPropagation();
    const wb_id = this.id.split("-")[3];
    const wb_comment = $("#wb-comment-for-" + wb_id);
    const comment_area_hidde = $("#wb-comment-text-" + wb_id).is(":hidden")?true:false;
    if(!wb_comment.hasClass("wb-comment-active")) {
       $('.wb-comment').removeClass("wb-comment-active");
       wb_comment.addClass("wb-comment-active");
    }
    if(this.id.split("-")[2] == "reply") {
      $("#wb-comment-text-" + wb_id).val('@' +  $("#wb-user-name-" + wb_id).text() +': ');
      $("#wb-comment-input-" + wb_id + " .emoji-wysiwyg-editor").html('<div>@' +  $(this).attr('for') +':&nbsp; </div>');
      $("#wb-comment-replyid-" + wb_id).val($(this).attr('reply-id'));
    }
    comment_area_hidde ? $("#wb-comment-input-" + wb_id + " .emoji-wysiwyg-editor").focusEnd() : $("#wb-comment-text-" + wb_id).focusEnd();

  });
  $('.wb-comment').click(function(event){
    event.stopPropagation();
  });

  //For Weibo forward
  $('.wb-forward-icon').bind('click', function(){
    const wb_id = this.id.split("-")[3];
    const wb_forward_content = $("#wb-user-name-" + wb_id).html().replace("\">","\">@") + " : "  + $("#wb-user-content-" + wb_id +" p").html();
    const wb_forward_default = '//' + $("#wb-user-name-" + wb_id).html().replace("\">","\">@") + ": "  + $("#wb-user-content-" + wb_id +" p").html();
    if($("#wb-content-from-" + wb_id).length > 0) { //判断是否是微博是转发
      $('.wb-forward-box-content').html($("#wb-content-from-" + wb_id +" p").html().replace("\">","\">@"));
      $('.wb-forward-box-form .emoji-wysiwyg-editor').html(wb_forward_default);
      $('#wb-forward-from').val($('#wb-content-from-' + wb_id).attr('from'));
    }
    else {
      $('.wb-forward-box-content').html(wb_forward_content);
      $('.wb-forward-box-form .emoji-wysiwyg-editor').html("");
      $('#wb-forward-from').val(wb_id);
    }

    layer.open({
      type: 1,
      title: ['<b>Forward</b>'].join(""),
      area: ['500px', 'auto'],
      zIndex: 666,
      moveType: 1,
      shadeClose: false,
      content: $('.wb-forward-box')
    });
    if($(window).width() > 600) // 判断访问设备
      $(".layui-layer-content .emoji-wysiwyg-editor").focusBegin();
    else
      $('.layui-layer').css({'width': $(window).width()*0.8, 'height': $('.layui-layer').height()+20, 'left': $(window).width()*0.1});
  });
  // Run EmojiArea.js setting
  $('textarea').each(function(_, v) {
    const $button = $(this).closest('form').find('.emoji-button')
    $(this).emojiarea({ button: $button.length && $button })
  })
  // Run At.js setting
  $(".emoji-wysiwyg-editor, textarea").atwho({
    at:"@",
    data: "/u/followingusers",
    headerTpl: '<div class="atwho-header">Member List<small>↑&nbsp;↓&nbsp;</small></div>',
    insertTpl: '@${name}',
    displayTpl: "<li>${name}</li>",
    startWithSpace: false,
    limit: 200
  });

  //For notice reply
  $('.notice-comment-reply').on('click', function(e) {
    e.stopPropagation();
    $(this).addClass("notice-comment-active");
    const notice_id = this.id.split("-")[3];
    const notice_comment = $("#notice-comment-input-" + notice_id + " .emoji-wysiwyg-editor")
    if(notice_comment.text() == "") {
      notice_comment.html($(this).attr('for'));
      notice_comment.focusEnd();
    }
  });

  $('a[data-method="delete"]').on("click", function(e) {
    var $this, message, url;

    e.stopPropagation();
    e.preventDefault();
    e.stopImmediatePropagation();

    $this = $(this);
    message = $this.data("confirm") || "删除吗？";
    url = $this.attr("href");

    layer.confirm(message, function(idx) {
      $.ajax({
        url: url + ".js",
        method: "delete",
        dataType: "json"
      }).done(function(data) {
        var $root, deleted, errorMessage, root;
        if (data.success) {
          deleted = $this.data("deleted");
          root = $this.data("root");
          if (root) {
            $root = $this.closest(root);
            $root.fadeOut("slow", function() {
              $root.remove();
            });
          }
          if (deleted) {
            eval(deleted);
          }
        } else {
          errorMessage = data.data && data.data.message;
          errorMessage && layer.alert(errorMessage);
        }
      }).fail(function() {
        layer.alert("删除失败！");
      }).always(function() {
        layer.close(idx);
      });
    });
  });

  //Check new messages
  function CheckNewMessages() {
    $.get("/u/noticemessages", function(notice){
      $.each(notice, function(k,v){
        v > 0 ? $('.wb-notice-' + k).text(v).show() : $('.wb-notice-' + k).hide()
      });
    });
  }
});

// Lightbox
$(document).on('click', '[data-toggle="lightbox"]', function(event) {
  event.preventDefault();
  $(this).ekkoLightbox();
});

//Cancel show
$(document).click(function(){
  $('.wb-comment').removeClass("wb-comment-active");
  $('.notice-comment-reply').removeClass("notice-comment-active");
  $('.wb-option-menu, .notice-option-menu').hide('fast');
  if($('.wb-post-preview').is(":hidden") && $('.wb-post-fuction-position small').length < 1)
    $('.wb-post .emoji-button, .wb-post-fuction').slideUp(200);
});

$(document).on("turbolinks:before-cache", function() {
  // Remove all wysiwyg editor
  $('.emoji-wysiwyg-editor').remove()
  $('.emoji-button').remove()
  $('.wb-post-fuction').removeAttr('style').hide()
 })
