require("@rails/ujs").start()
require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")

// css
import "bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import "ekko-lightbox/dist/ekko-lightbox.css"

import "../styles/global"
import "../styles/font-awesome"
import "../styles/jquery.atwho"
import "../styles/jquery.emojiarea"
import "../styles/jquery.tabslet"

// js
import "layui-layer"
import "tabslet"
import "ekko-lightbox"
import "../pages/common"
import "../pages/global"
import "../pages/emoji"
