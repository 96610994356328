import $ from 'jquery'
import QRCode from 'davidshimjs-qrcodejs'

window.jQuery = $
window.$ = $

window.newQrcode = function(ele, text, options) {
  if (options == null) {
    options = {};
  }
  if (ele instanceof jQuery) {
    ele = ele[0];
  }
  return new QRCode(ele, {
    text: text,
    width: options.width || 200,
    height: options.height || 200,
    colorDark: options.colorDark || "#000000",
    colorLight: options.colorLight || "#ffffff",
    correctLevel: options.correctLevel || QRCode.CorrectLevel.H
  });
}
