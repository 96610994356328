import '.../utils/jquery.emojiarea'

$.emojiarea.path = '/images/emoji/unicode/'
$.emojiarea.icons = {
  ':grinning:': '1f600.png',
  ':grimacing:': '1f62c.png',
  ':grin:': '1f601.png',
  ':joy:': '1f602.png',
  ':smiley:': '1f603.png',
  ':smile:': '1f604.png',
  ':sweat_smile:': '1f605.png',
  ':laughing:': '1f606.png',
  ':innocent:': '1f607.png',
  ':wink:': '1f609.png',
  ':blush:': '1f60a.png',
  ':yum:': '1f60b.png',
  ':relieved:': '1f60c.png',
  ':heart_eyes:': '1f60d.png',
  ':kissing_heart:': '1f618.png',
  ':kissing:': '1f617.png',
  ':kissing_smiling_eyes:': '1f619.png',
  ':kissing_closed_eyes:': '1f61a.png',
  ':stuck_out_tongue_winking_eye:': '1f61c.png',
  ':stuck_out_tongue_closed_eyes:': '1f61d.png',
  ':stuck_out_tongue:': '1f61b.png',
  ':sunglasses:': '1f60e.png',
  ':smirk:': '1f60f.png',
  ':no_mouth:': '1f636.png',
  ':neutral_face:': '1f610.png',
  ':expressionless:': '1f611.png',
  ':unamused:': '1f612.png',
  ':flushed:': '1f633.png',
  ':disappointed:': '1f61e.png',
  ':worried:': '1f61f.png',
  ':angry:': '1f620.png',
  ':rage:': '1f621.png',
  ':pensive:': '1f614.png',
  ':confused:': '1f615.png',
  ':persevere:': '1f623.png',
  ':confounded:': '1f616.png',
  ':tired_face:': '1f62b.png',
  ':weary:': '1f629.png',
  ':triumph:': '1f624.png',
  ':open_mouth:': '1f62e.png',
  ':scream:': '1f631.png',
  ':fearful:': '1f628.png',
  ':cold_sweat:': '1f630.png',
  ':hushed:': '1f62f.png',
  ':frowning:': '1f626.png',
  ':anguished:': '1f627.png',
  ':cry:': '1f622.png',
  ':disappointed_relieved:': '1f625.png',
  ':sleepy:': '1f62a.png',
  ':sweat:': '1f613.png',
  ':sob:': '1f62d.png',
  ':dizzy_face:': '1f635.png',
  ':astonished:': '1f632.png',
  ':mask:': '1f637.png',
  ':sleeping:': '1f634.png',
  ':zzz:': '1f4a4.png',
  ':poop:': '1f4a9.png',
  ':smiling_imp:': '1f608.png',
  ':imp:': '1f47f.png',
  ':japanese_ogre:': '1f479.png',
  ':japanese_goblin:': '1f47a.png',
  ':skull:': '1f480.png',
  ':ghost:': '1f47b.png',
  ':alien:': '1f47d.png',
  ':smiley_cat:': '1f63a.png',
  ':smile_cat:': '1f638.png',
  ':joy_cat:': '1f639.png',
  ':heart_eyes_cat:': '1f63b.png',
  ':smirk_cat:': '1f63c.png',
  ':kissing_cat:': '1f63d.png',
  ':scream_cat:': '1f640.png',
  ':crying_cat_face:': '1f63f.png',
  ':pouting_cat:': '1f63e.png',
  ':raised_hands:': '1f64c.png',
  ':clap:': '1f44f.png',
  ':wave:': '1f44b.png',
  ':thumbsup:': '1f44d.png',
  ':thumbsdown:': '1f44e.png',
  ':punch:': '1f44a.png',
  ':ok_hand:': '1f44c.png',
  ':open_hands:': '1f450.png',
  ':muscle:': '1f4aa.png',
  ':pray:': '1f64f.png',
  ':point_up_2:': '1f446.png',
  ':point_down:': '1f447.png',
  ':point_left:': '1f448.png',
  ':point_right:': '1f449.png',
  ':nail_care:': '1f485.png',
  ':lips:': '1f444.png',
  ':tongue:': '1f445.png',
  ':ear:': '1f442.png',
  ':nose:': '1f443.png',
  ':eyes:': '1f440.png',
  ':bust_in_silhouette:': '1f464.png',
  ':busts_in_silhouette:': '1f465.png',
  ':baby:': '1f476.png',
  ':boy:': '1f466.png',
  ':girl:': '1f467.png',
  ':man:': '1f468.png',
  ':woman:': '1f469.png',
  ':person_with_blond_hair:': '1f471.png',
  ':older_man:': '1f474.png',
  ':older_woman:': '1f475.png',
  ':man_with_gua_pi_mao:': '1f472.png',
  ':man_with_turban:': '1f473.png',
  ':cop:': '1f46e.png',
  ':construction_worker:': '1f477.png',
  ':guardsman:': '1f482.png',
  ':santa:': '1f385.png',
  ':angel:': '1f47c.png',
  ':princess:': '1f478.png',
  ':bride_with_veil:': '1f470.png',
  ':walking:': '1f6b6.png',
  ':runner:': '1f3c3.png',
  ':dancer:': '1f483.png',
  ':dancers:': '1f46f.png',
  ':couple:': '1f46b.png',
  ':two_men_holding_hands:': '1f46c.png',
  ':two_women_holding_hands:': '1f46d.png',
  ':bow:': '1f647.png',
  ':information_desk_person:': '1f481.png',
  ':no_good:': '1f645.png',
  ':ok_woman:': '1f646.png',
  ':raising_hand:': '1f64b.png',
  ':person_with_pouting_face:': '1f64e.png',
  ':person_frowning:': '1f64d.png',
  ':haircut:': '1f487.png',
  ':massage:': '1f486.png',
  ':couple_with_heart:': '1f491.png',
  ':couplekiss:': '1f48f.png',
  ':family:': '1f46a.png',
  ':womans_clothes:': '1f45a.png',
  ':shirt:': '1f455.png',
  ':jeans:': '1f456.png',
  ':necktie:': '1f454.png',
  ':dress:': '1f457.png',
  ':bikini:': '1f459.png',
  ':kimono:': '1f458.png',
  ':lipstick:': '1f484.png',
  ':kiss:': '1f48b.png',
  ':footprints:': '1f463.png',
  ':high_heel:': '1f460.png',
  ':sandal:': '1f461.png',
  ':boot:': '1f462.png',
  ':mans_shoe:': '1f45e.png',
  ':athletic_shoe:': '1f45f.png',
  ':womans_hat:': '1f452.png',
  ':tophat:': '1f3a9.png',
  ':mortar_board:': '1f393.png',
  ':crown:': '1f451.png',
  ':school_satchel:': '1f392.png',
  ':pouch:': '1f45d.png',
  ':purse:': '1f45b.png',
  ':handbag:': '1f45c.png',
  ':briefcase:': '1f4bc.png',
  ':eyeglasses:': '1f453.png',
  ':ring:': '1f48d.png',
  ':closed_umbrella:': '1f302.png',
  ':dog:': '1f436.png',
  ':cat:': '1f431.png',
  ':mouse:': '1f42d.png',
  ':hamster:': '1f439.png',
  ':rabbit:': '1f430.png',
  ':bear:': '1f43b.png',
  ':panda_face:': '1f43c.png',
  ':koala:': '1f428.png',
  ':tiger:': '1f42f.png',
  ':cow:': '1f42e.png',
  ':pig:': '1f437.png',
  ':pig_nose:': '1f43d.png',
  ':frog:': '1f438.png',
  ':octopus:': '1f419.png',
  ':monkey_face:': '1f435.png',
  ':see_no_evil:': '1f648.png',
  ':hear_no_evil:': '1f649.png',
  ':speak_no_evil:': '1f64a.png',
  ':monkey:': '1f412.png',
  ':chicken:': '1f414.png',
  ':penguin:': '1f427.png',
  ':bird:': '1f426.png',
  ':baby_chick:': '1f424.png',
  ':hatching_chick:': '1f423.png',
  ':hatched_chick:': '1f425.png'
}
